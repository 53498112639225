<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
       <agreements-manager :username="username" :aId="aId" :active="true"></agreements-manager>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AgreementsManager from '@/components/Agreements/AgreementsManager'

export default {
  props: ['username', 'aId'],
  components: {
    AgreementsManager
  }
}
</script>

<style>

</style>
