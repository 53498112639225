<template>
  <v-card>
    <loading-bar :value="loading"></loading-bar>
    <v-card-text class="pa-0">
      <v-window v-model="window" touchless>
        <!-- NEW/EDIT -->
        <v-window-item :value="0">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Title"
                  v-model="title"
                  color="color3"
                  placeholder="Enter a title for your agreement"
                ></v-text-field>
                <v-file-input
                  label="Agreement"
                  prepend-icon=""
                  append-icon="fas fa-paperclip"
                  placeholder="Select your file"
                  color="color3"
                  accept=".pdf,.txt"
                  v-model="file"
                  truncate-length="100"
                  show-size
                  v-if="!agreement"
                ></v-file-input>
                <v-textarea
                  v-model="comments"
                  label="Comments"
                  hint="These are internal comments for your organization"
                  color="color3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
        <!-- CURRENT -->
        <v-window-item :value="1">
            <v-card-text v-if="agreement">
              <div class="text-end">
                <v-btn color="color3" text :to="`${agreement.id}/players`">See All Signature Status</v-btn>
              </div>
              <v-card class="mb-3">
                <v-card-title>
                  {{agreement.title}}<br>v{{agreement.version}}
                </v-card-title>
                <v-card-text>
                  <div class="error--text caption" v-if="agreement.type">* This agreement is currently required</div>
                  Comments: <span class="font-italic font-weight-light">{{agreement.comments}}</span>
                            <div class="cap mt-2">Created On: {{agreement.dtCreated | unixToLocalDt}} By: {{agreement.userCreated}}</div>
                            <div class="cap">Last Modified On: {{agreement.dtModified | unixToLocalDt}} By: {{agreement.userModified}}</div>
                </v-card-text>
              </v-card>
              <!-- BUTTONS -->
                <v-btn
                  color="color3 color3Text--text"
                  @click="open(agreement)"
                  class="mr-2 mb-2"
                >
                  View Agreement<v-icon class="ml-3" small>fas fa-external-link</v-icon>
                </v-btn>
                <v-btn
                  v-if="window === 1"
                  color="color3 color3Text--text"
                  @click.stop="window=3"
                  :loading="loading"
                  class="mr-2 mb-2"
                >
                  {{agreement && agreement.type ? 'Remove Requirement' : 'Make Required'}}
                </v-btn>
                <v-btn
                  color="color3 color3Text--text"
                  @click.stop="edit"
                  :loading="loading"
                  class="mr-2 mb-2"
                >
                  edit
                </v-btn>
                <v-btn
                  color="color3 color3Text--text"
                  @click.stop="revisie"
                  :loading="loading"
                  class="mr-2 mb-2"
                >
                  Add revision
                </v-btn>
              <div class="mt-3">History</div>
              <v-data-table
                dense
                :headers="[
                  {text: 'Version', value: 'version', sortable: false},
                  {text: 'Comments', value: 'comments', sortable: false},
                  {text: 'Required', value: 'type', sortable: false},
                  {text: '', value: 'id', sortable: false}
                ]"
                :items="agreement.history"
                hide-default-footer
                :options="{ sortBy: ['id'], sortDesc: [true], itemsPerPage: -1 }"
              >
                <template v-slot:[`item.type`]="{item}">
                  {{item.type ? 'Yes' : 'No'}}
                </template>
                <template v-slot:[`item.id`]="{item}">
                  <v-btn color="color3" text icon small :href="item.url" target="_blank">
                    <v-icon>fas fa-external-link</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
        </v-window-item>
        <!-- UPDATE -->
        <v-window-item :value="2">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-file-input
                  label="New Version"
                  prepend-icon=""
                  append-icon="fas fa-paperclip"
                  placeholder="Select your file"
                  color="color3"
                  accept=".pdf,.txt"
                  v-model="file"
                  truncate-length="100"
                ></v-file-input>
                <v-textarea
                  v-model="comments"
                  label="Comments"
                  hint="These are internal comments for your organization"
                  color="color3"
                ></v-textarea>
                <v-select
                  :items="['Minor', 'Major']"
                  v-model="updateType"
                  label="Update Type"
                  color="color3"
                  item-color="color3"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
        <!-- REQUIRE -->
        <v-window-item :value="3">
          <v-card-text class="pb-0">
            <div class="title">Require for all participants for every event?</div>
            <p>By marking an agreement required, we will send emails out to every participant that registers for any event you host asking them to digitally sign that they  have seen and agree to the agreement.</p>
            <p>Once they have agree to it through our system they will not have to do it again, until you add a revision that is marked "Major". </p>
            <p class="title">
              {{agreement && agreement.type ? 'Remove Requirement' : 'Make Required'}} ?
            </p>
            <v-btn color="success" class="mr-2" @click.stop="toggleRequire" :loading="loading">yes</v-btn>
            <v-btn color="error" @click.stop="window = 1" :disabled="loading">No</v-btn>
          </v-card-text>
        </v-window-item>
      </v-window>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="[0, 2].includes(window)"
        color="success"
        :disabled="!saveable"
        @click.stop="onSaveClick(false)"
        :loading="loading"
      >
        {{window === 0 ? 'save' : 'Upload'}}
      </v-btn>
      <v-btn
        v-if="[0, 2].includes(window)"
        color="error"
        @click.stop="cancel"
        :loading="loading"
      >Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['agreement', 'active', 'username'],
  data () {
    return {
      dialog: false,
      window: 0,
      disabled: false,
      title: null,
      file: null,
      comments: null,
      loading: false,
      editing: false,
      updateType: 'Minor',
      required: false
    }
  },
  computed: {
    ...mapGetters(['getPageInfo']),
    saveable () {
      switch (this.window) {
        case 0:
          return this.agreement ? !!(this.title && (this.title !== this.agreement.title || this.comments !== this.agreement.comments)) : !!(this.title && this.file)
        case 2:
          return !!this.file
        default:
          break
      }
      return false
    },
    organization () {
      return this.getPageInfo(this.username)
    },
    dto () {
      const dto = new FormData()
      dto.append('id', this.agreement ? this.agreement.id : 0)
      dto.append('organizationId', this.organization.id)
      dto.append('title', this.title || this.agreement.title)
      dto.append('updateType', this.updateType)
      dto.append('type', this.agreement ? this.agreement.type : 0)
      this.file && dto.append('files', this.file, this.file.name)
      this.comments && dto.append('comments', this.comments)
      return dto
    }
  },
  methods: {
    open (agreement) {
      const url = agreement.url
      window.open(url, '_blank')
      // if (url.endsWith('.pdf')) {
      // }
    },
    edit () {
      this.title = this.agreement.title
      this.comments = this.agreement.comments
      this.window = 0
    },
    revisie () {
      this.title = this.agreement.title
      this.comments = null
      this.window = 2
    },
    init () {
      this.file = null
      this.comments = null
      this.title = this.agreement ? this.agreement.title : null
      this.window = this.agreement ? 1 : 0
    },
    onSaveClick (dto) {
      this.loading = true
      this.$VBL.agreements.organization.post(dto || this.dto)
        .then(r => {
          this.$emit('saved', r.data)
          this.window = 1
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    cancel () {
      if (this.agreement) {
        this.window = 1
      } else { this.$emit('cancel-click') }
    },
    toggleRequire () {
      const dto = new FormData()
      dto.append('id', this.agreement.id)
      dto.append('organizationId', this.organization.id)
      dto.append('title', this.agreement.title)
      dto.append('updateType', this.updateType)
      dto.append('comments', this.agreement.comments)
      dto.append('type', this.agreement.type === 0 ? 1 : 0)
      this.onSaveClick(dto)
    }
  },
  watch: {
    agreement: function (val) {
      val && this.init()
    },
    dialog: function (val) {
      if (!val) {
        this.$emit('closed')
        this.window = 1
      }
    },
    active: 'init'
  },
  mounted () {
    this.init()
  }
}
</script>
