var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text","flat":""}},[_c('v-toolbar-title',[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.onCancelClick($event)}}},[_vm._v("Agreements")]),(_vm.windowCalc > 0)?[_c('v-icon',{staticClass:"mx-3",attrs:{"color":"color1Text"}},[_vm._v("fas fa-angle-right")]),_vm._v(" "+_vm._s(_vm.selectedAgreement ? _vm.selectedAgreement.title : 'New')+" ")]:_vm._e()],2),_c('v-spacer'),_c('v-fab-transition',[(!_vm.loading && !_vm.windowCalc)?_c('v-btn',{attrs:{"color":"color3Text color3--text","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.newAgreement($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-plus")])],1):_vm._e()],1)],1),_c('loading-bar',{attrs:{"value":_vm.loading}}),_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.windowCalc),callback:function ($$v) {_vm.windowCalc=$$v},expression:"windowCalc"}},[_c('v-window-item',{attrs:{"value":0}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.agreements,"options":_vm.pagination,"item-key":"id","multi-sort":"","loading":_vm.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"slot":"progress","color":"color3","indeterminate":""},slot:"progress"})]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type ? 'Yes' : 'No')+" ")]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.version)+" ")]}},{key:"item.dtModified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unixToLocalDt")(item.dtModified))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"color3 color3Text--text","to":{ name: 'organization-agreement', params: { aId: item.id }},"fab":"","small":""}},[_c('v-icon',[_vm._v("fas fa-eye")])],1)]}}],null,true)}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-btn',{staticClass:"xsmall",attrs:{"color":"color3","text":"","icon":"","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.getAll($event)}}},[_c('v-icon',[_vm._v("fas fa-redo-alt")])],1)],1)],1)],1),_c('v-window-item',{attrs:{"value":1}},[_c('v-card',[_c('v-card-text',[_c('agreement-details',{ref:"details",attrs:{"agreement":_vm.selectedAgreement,"username":_vm.username,"active":_vm.windowCalc===1},on:{"cancel-click":_vm.onCancelClick,"saved":_vm.onSaved}})],1),_c('v-card-actions',[_c('v-fab-transition',[_c('v-btn',{attrs:{"color":"color3Text color3--text","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.onCancelClick($event)}}},[_c('v-icon',[_vm._v("fas fa-caret-left")])],1)],1)],1)],1)],1),_c('v-window-item',{attrs:{"value":2}},[(_vm.selectedAgreement)?_c('v-card',[_c('v-card-text',[_c('agreement-player-statuses',{attrs:{"agreement":_vm.selectedAgreement,"active":_vm.windowCalc===2}})],1),_c('v-card-actions',[_c('v-fab-transition',[(_vm.selectedAgreement)?_c('v-btn',{attrs:{"color":"color3Text color3--text","fab":"","to":{ name: 'organization-agreement', params: { aId: _vm.selectedAgreement.id }},"exact":""}},[_c('v-icon',[_vm._v("fas fa-caret-left")])],1):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }