<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" flat>
      <v-toolbar-title>
        <span @click.stop="onCancelClick">Agreements</span>
        <template v-if="windowCalc > 0">
          <v-icon color="color1Text" class="mx-3">fas fa-angle-right</v-icon> {{selectedAgreement ? selectedAgreement.title : 'New'}}
        </template>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          v-if="!loading && !windowCalc"
          color="color3Text color3--text"
          fab small
          @click.stop="newAgreement"
        >
          <v-icon small>fas fa-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-window v-model="windowCalc" touchless>
      <!-- AGREEMENT LIST -->
      <v-window-item :value="0">
        <v-data-table
          :headers="headers"
          :items="agreements"
          :options.sync="pagination"
          item-key="id"
          multi-sort
          :loading="loading"
        >
          <template v-slot:progress>
            <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{item.type ? 'Yes' : 'No'}}
          </template>
          <template v-slot:[`item.version`]="{ item }">
            {{item.version}}
          </template>
          <template v-slot:[`item.dtModified`]="{ item }">
            {{item.dtModified | unixToLocalDt}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="color3 color3Text--text"
              :to="{ name: 'organization-agreement', params: { aId: item.id }}"
              fab small
            >
              <v-icon>fas fa-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-container fluid>
          <v-row dense justify="end">
            <v-btn color="color3" text class="xsmall" icon @click.stop="getAll" :loading="loading">
              <v-icon>fas fa-redo-alt</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-window-item>
      <!-- AGREEMENT DETAILS -->
      <v-window-item :value="1">
        <v-card>
          <v-card-text>
            <agreement-details
              ref="details"
              :agreement="selectedAgreement"
              :username="username"
              @cancel-click="onCancelClick"
              @saved="onSaved"
              :active="windowCalc===1"
            ></agreement-details>
          </v-card-text>
          <v-card-actions>
            <v-fab-transition>
              <v-btn
                color="color3Text color3--text"
                fab
                @click.stop="onCancelClick"
              >
                <v-icon>fas fa-caret-left</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card-actions>
        </v-card>
      </v-window-item>
      <!-- AGREEMENT PLAYERS -->
      <v-window-item :value="2">
        <v-card v-if="selectedAgreement">
          <v-card-text>
            <agreement-player-statuses
              :agreement="selectedAgreement"
              :active="windowCalc===2"
            ></agreement-player-statuses>
          </v-card-text>
          <v-card-actions>
            <v-fab-transition>
              <v-btn
                color="color3Text color3--text"
                fab
                v-if="selectedAgreement"
                :to="{ name: 'organization-agreement', params: { aId: selectedAgreement.id }}"
                exact
              >
                <v-icon>fas fa-caret-left</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card-actions>
        </v-card>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import AgreementDetails from './AgreementDetails'
const AgreementPlayerStatuses = () => import('./AgreementPlayerStatuses')

export default {
  props: ['username', 'aId'],
  data () {
    return {
      window: 0,
      loading: false,
      agreements: [],
      headers: [
        { text: 'Id', value: 'id', align: 'left' },
        { text: 'Title', value: 'title', align: 'left' },
        { text: 'Required', value: 'type', align: 'center' },
        { text: 'Version', value: 'version', align: 'center' },
        { text: 'Comments', value: 'comments', align: 'center' },
        { text: 'Last Modified', value: 'dtModified', align: 'center' },
        { text: '', value: 'actions', align: 'center', sortable: false }
      ],
      pagination: { itemsPerPage: 10, sortBy: ['type', 'dtModified'], sortDesc: [true, true] },
      selectedAgreement: null,
      dialog: false
    }
  },
  computed: {
    queryId () {
      return this.$route.query.a
    },
    propAgreement () {
      return this.aId && this.agreements.length && this.agreements.find(f => f.id === +this.aId)
    },
    windowCalc () {
      if (this.$route.name === 'organization-agreements') return 0
      return this.$route.name === 'organization-agreement-players' ? 2 : 1
    }
  },
  methods: {
    getAll () {
      this.loading = true
      this.$VBL.agreements.organization.getAll(this.username)
        .then(r => { this.agreements = r.data })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    newAgreement () {
      this.$router.push({ name: 'organization-agreement', params: { aId: 'new' } })
    },
    onCancelClick () {
      if (this.$route.name !== 'organization-agreements') this.$router.push({ name: 'organization-agreements' })
    },
    onSaved (agreement) {
      const i = this.agreements.findIndex(f => f.id === agreement.id)
      if (i < 0) {
        this.agreements.push(agreement)
      } else {
        this.agreements.splice(i, 1, agreement)
      }
      this.$router.push({ name: 'organization-agreement', params: { aId: agreement.id } })
    },
    selectAgreement (a) {
      this.selectedAgreement = a
    }
  },
  watch: {
    selectedAgreement: function (val) {
      if (val) this.window = 1
    },
    propAgreement: function (val) {
      this.selectAgreement(val)
    }
  },
  mounted () {
    this.getAll()
  },
  components: {
    AgreementDetails,
    AgreementPlayerStatuses
  }
}
</script>

<style scoped>

</style>
